import { getPageType, getPage, getGlobal } from 'lib/contentful';
import SEO from 'components/shared/SEO';
import { useEffect } from 'react';
import PageGeneral from 'components/pages/general';
import { get, keys } from 'lib/utils/localStorage';
import useVisitorTypeTracking from 'hooks/tracking/useVisitorTypeTracking';
import { useSettings } from 'contexts';
import { useRouter } from 'next/router';
import Layout from 'components/layout';
import { reformatLocaleToContentfulLocale } from 'lib/utils/helpers';
import getSalons from 'lib/firebase/getSalons';
import CountrySelectorModal from '../components/pages/countrySelector';

// eslint-disable-next-line arrow-body-style
const Home = ({ page, type, locale, salonData, previewData, globalModules }) => {
  const { salons, setSalons } = useSettings();
  const router = useRouter();

  useEffect(() => {
    if (salonData?.[0]?.country !== salons?.[0]?.country) setSalons(salonData);
  }, [salonData, setSalons, salons]);

  useEffect(() => {
    if (!salonData.length) return;
    const customerEmail = get(keys.LOGGED_IN_CUSTOMER);
    const salon = salonData.find(
      s => s.salonEmail === customerEmail || s.parentEmail === customerEmail
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useVisitorTypeTracking({
      visitorType: salon?.salonNo || 'KEUNE Cosmetics',
      location: { action: router },
    });
  }, [router, salonData, locale]);

  return (
    <Layout locale={locale} previewData={previewData} globalModules={globalModules} pageData={page}>
      <>
        {page?.seo && <SEO seo={page.seo} pageData={page} />}
        <PageGeneral locale={locale} data={page} resources={globalModules} />
        <CountrySelectorModal locale={locale} globalModules={globalModules} pageData={page} />
      </>
    </Layout>
  );
};

export async function getStaticProps({ locale, preview = false, previewData = null }) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);

  const type = await getPageType('/', reformattedLocale);
  const page = await getPage(type, '/', reformattedLocale, preview);

  if (!page) {
    return {
      notFound: true,
    };
  }

  page.locale = locale;
  if (page.seo) {
    page.seo.locale = locale;
  }

  /**
   * We are retrieving all salon data from Firebase, but since we only need
   * the salon number, parent email and customer email (for tracking purposes), we map the salons
   * into a new array consisting only of these 3 properties, which increased performance MASSIVELY.
   */

  const globalModules = await getGlobal(
    [
      'productpage',
      'secondpartproductpage',
      'collection',
      'cookies',
      'announcement-bar',
      'countryselector',
      'firstcountryselectorlocales',
    ],
    reformattedLocale
  );

  const salons = await getSalons(locale);

  return {
    props: { page, type, locale, salonData: salons, previewData, globalModules },
  };
}

export default Home;
